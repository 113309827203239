import { HttpError } from 'react-admin';

interface FetchOptions {
  headers?: Headers;
  body?: BodyInit;
  user?: {
    authenticated: boolean;
    token: string;
  };
  [key: string]: any;
}

interface MappedResponse {
  status: number;
  statusText: string;
  headers: Headers;
  body: string;
}

interface JsonResponse {
  status: number;
  headers: Headers;
  body: string;
  json?: any;
}

const fetchJson = async (url: string, options: FetchOptions = {}): Promise<JsonResponse> => {
  const requestHeaders =
    options.headers ||
    new Headers({
      Accept: 'application/json',
    });

  if (
    !requestHeaders.has('Content-Type') &&
    !(options && options.body && options.body instanceof FormData)
  ) {
    requestHeaders.set('Content-Type', 'application/json');
  }

  if (options.user && options.user.authenticated && options.user.token) {
    requestHeaders.set('Authorization', options.user.token);
  }

  const response = await fetch(url, { ...options, headers: requestHeaders });

  const text = await response.text();

  const mappedResponse: MappedResponse = {
    status: response.status,
    statusText: response.statusText,
    headers: response.headers,
    body: text,
  };
  let json: any;

  try {
    json = JSON.parse(mappedResponse.body);
  } catch (e) {
    // not json, no big deal
  }

  if (mappedResponse.status < 200 || mappedResponse.status >= 300) {
    throw new HttpError(
      (json && json.error && json.error.message) ||
        (json && json.message) ||
        mappedResponse.body ||
        mappedResponse.statusText,
      mappedResponse.status,
      json || mappedResponse.body
    );
  }
  return Promise.resolve({
    status: mappedResponse.status,
    headers: mappedResponse.headers,
    body: mappedResponse.body,
    json: json,
  });
};

export default fetchJson;
