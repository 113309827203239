import React, { memo, useState } from 'react';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { useDataProvider, useRefresh, useNotify } from 'react-admin';

import { makeStyles } from '@material-ui/core';

import { FraudDetailsSection } from './FraudDetailsSection';
import EnableUserButton from './EnableUserButton';
import ChangeEmailButton from './ChangeEmailButton';
import UserStatusField from './UserStatusField';
import ResetPasswordButton from './ResetPasswordButton';
import VerificationStatus from './VerificationStatus';
import BypassFraudButton from './BypassFraudButton';

const useStyles = makeStyles((theme) => ({
  managementCard: {
    padding: theme.spacing(3),
  },

  statusContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  label: {
    fontSize: 16,
    color: theme.palette.secondary.contrastText,
    fontWeight: 700,
  },

  button: {
    color: theme.palette.secondary.contrastText,
    textTransform: 'none',
    minWidth: '110px',
  },

  action: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  userSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '8px',
  },

  divider: {
    padding: 0,
  },

  container: {
    marginTop: theme.spacing(2),
  },

  verificationContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  verificationStatuses: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '100%',
  },
}));

const ManagementTab = ({ user }) => {
  const {
    cognitoStatus,
    id: rolloId,
    cognitoUserId: cognitoId,
    fraudAllowedAt: bypassFraud,
    phoneVerified,
  } = user;

  const emailVerified = cognitoStatus === 'CONFIRMED';

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();

  const [loading, setLoading] = useState<boolean>(false);

  const handleVerifyEmail = async () => {
    try {
      setLoading(true);

      await dataProvider.post('confirmUser', { data: { rolloId, cognitoId } });

      refresh();
    } catch (error: any) {
      notify(
        typeof error === 'string' ? error : error.message || 'ra.notification.http_error',
        'warning'
      );
    } finally {
      setLoading(false);
    }
  };

  const getVerificationLabel = () => {
    if (!phoneVerified && !emailVerified) {
      return 'Verify Email & Phone';
    }

    if (phoneVerified && !emailVerified) {
      return 'Verify Email';
    }

    if (!phoneVerified && emailVerified) {
      return 'Verify Phone';
    }
  };

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item md={6} xs={12}>
        <Card className={classes.managementCard}>
          <Box className={classes.verificationContainer}>
            <Box className={classes.verificationStatuses}>
              <Box className={classes.statusContainer}>
                <Typography className={classes.label}>Email address</Typography>

                <VerificationStatus
                  verified={emailVerified}
                  label={emailVerified ? 'Verified' : 'Unverified'}
                />
                <Box className={classes.userSection}>
                  {cognitoStatus === 'UNCONFIRMED' && (
                    <Button
                      variant="outlined"
                      className={classes.button}
                      onClick={handleVerifyEmail}
                      disabled={loading}>
                      Verify email
                    </Button>
                  )}
                  <ChangeEmailButton user={user} />
                </Box>
              </Box>

              <Divider variant="middle" />

              <Box className={classes.statusContainer}>
                <Typography className={classes.label}>Phone number</Typography>

                <VerificationStatus
                  verified={phoneVerified}
                  label={phoneVerified ? 'Verified' : 'Unverified'}
                />
              </Box>
            </Box>

            {(!phoneVerified || !emailVerified) && (
              <Button
                variant="outlined"
                className={classes.button}
                onClick={handleVerifyEmail}
                disabled={loading}>
                {getVerificationLabel()}
              </Button>
            )}
          </Box>
        </Card>
      </Grid>

      <Grid item md={6} xs={12}>
        <Card className={classes.managementCard}>
          <Grid container spacing={4}>
            <Grid xs={12} item container alignItems="center">
              <Grid xs={3}>
                <Typography className={classes.label}>User status</Typography>
              </Grid>

              <Grid xs={3}>
                <UserStatusField record={user} />
              </Grid>

              <Grid xs={6} className={classes.action}>
                <Box className={classes.userSection}>
                  <EnableUserButton user={user} />
                  <ResetPasswordButton user={user} />
                </Box>
              </Grid>
            </Grid>

            <Grid item xs={12} className={classes.divider}>
              <Divider variant="middle" />
            </Grid>

            <Grid item container xs={12} alignItems="center">
              <Grid xs={3}>
                <Typography className={classes.label}>Fraud monitoring</Typography>
              </Grid>

              <Grid xs={3}>
                <VerificationStatus
                  verified={!bypassFraud}
                  label={Boolean(bypassFraud) ? 'Disabled' : 'Enabled'}
                />
              </Grid>

              <Grid xs={6} className={classes.action}>
                <BypassFraudButton user={user} />
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <FraudDetailsSection user={user} />
      </Grid>
    </Grid>
  );
};

export default memo(ManagementTab);
